import { Disclosure } from "@headlessui/react"
import { lang } from "config"
import { useAuth } from "contexts/auth-context"
import { AnimatePresence, motion, useAnimationControls } from "framer-motion"
import { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"

// Tailwind
import resolveConfig from 'tailwindcss/resolveConfig'
import defaultTheme from 'tailwindcss/defaultTheme'
import { usePage } from "contexts/page-context"
import { Box, Receipt, Report, ReportAnalytics } from "components/icons/outline"

const Sidebar = ({ isSidebarOpen }) => {
    const url = useLocation().pathname
    const { can } = useAuth()
    const { configuration } = usePage()

    const fullConfig = resolveConfig(defaultTheme)

    const sidebar = useAnimationControls()

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    })

    // Listen to the window resize event
    useEffect(() => {
        // window.onresize = () => {
        //     setWindowSize({
        //         width: window.innerWidth,
        //         height: window.innerHeight,
        //     })
        // }

        const updateWindowSizeState = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            })
        }

        window.addEventListener('resize', updateWindowSizeState)

        return () => window.removeEventListener('resize', updateWindowSizeState)
    }, [])

    useEffect(() => {
        if (windowSize.width < parseInt(fullConfig.theme.screens.lg.replace('px', ''))) {
            if (isSidebarOpen) {
                sidebar.start({
                    x: 0,
                    zIndex: 'auto'
                })
            } else {
                sidebar.start({
                    x: '-4rem',
                    zIndex: '-1'
                })
            }
        } else {
            sidebar.set({
                x: 0,
                zIndex: 'auto'
            })
        }
    }, [isSidebarOpen, windowSize])

    return (
        <motion.div animate={sidebar} transition={{ type: 'spring', damping: 20 }} className="flex flex-col w-0 max-h-screen pt-12 space-y-16 font-sans lg:w-fit">
            <div className="ml-12 text-3xl">
                {process.env.REACT_APP_NAME}
            </div>
            <nav className="w-56 max-h-full px-4 pb-8 ml-8 space-y-8 overflow-x-visible overflow-y-auto text-sm">
                <div className="space-y-2">
                    <div className="text-xs">
                        {lang.pages}
                    </div>
                    <ul>
                        <li>
                            <Link to="/" state={{ back: null, from: null, transition: 'fade' }} className="flex items-center space-x-4">
                                <div className={`${url === '/' ? 'bg-neutral-900 ring-[1.5px] ring-offset-2 ring-neutral-900' : 'bg-neutral-700'} p-2 text-white rounded-full`}>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M19 8.71l-5.333 -4.148a2.666 2.666 0 0 0 -3.274 0l-5.334 4.148a2.665 2.665 0 0 0 -1.029 2.105v7.2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-7.2c0 -.823 -.38 -1.6 -1.03 -2.105"></path>
                                        <path d="M16 15c-2.21 1.333 -5.792 1.333 -8 0"></path>
                                    </svg>
                                </div>
                                <div>
                                    {lang.title.dashboard}
                                </div>
                                {url === '/' && (<div className="flex-grow border-t border-neutral-900"></div>)}
                            </Link>
                        </li>
                    </ul>
                </div>

                {(can('sales-report.view') || can('product-sales-report.view')) && (
                    <div className="space-y-2">
                        <div className="text-xs">
                            Sales
                        </div>
                        <ul className="space-y-6">
                            {can('sales-report.view') && (
                                <li>
                                    <Link to="/sales" state={{ back: null, from: null, transition: 'fade' }} className="flex items-center space-x-4">
                                        <div className={`${url === '/sales' ? 'bg-neutral-900 ring-[1.5px] ring-offset-2 ring-neutral-900' : 'bg-neutral-700'} p-2 text-white rounded-full`}>
                                            <Receipt className="w-6 h-6" />
                                        </div>
                                        <div>
                                            Sales
                                        </div>
                                        {url === '/sales' && (<div className="flex-grow border-t border-neutral-900"></div>)}
                                    </Link>
                                </li>
                            )}
                            {/* {can('sales-recon-report.view') && (
                                <li>
                                    <Link to="/sales-recon" state={{ back: null, from: null, transition: 'fade' }} className="flex items-center space-x-4">
                                        <div className={`${url === '/sales-recon' ? 'bg-neutral-900 ring-[1.5px] ring-offset-2 ring-neutral-900' : 'bg-neutral-700'} p-2 text-white rounded-full`}>
                                            <Report className="w-6 h-6" />
                                        </div>
                                        <div>
                                            Reconciliation
                                        </div>
                                        {url === '/sales-recon' && (<div className="flex-grow border-t border-neutral-900"></div>)}
                                    </Link>
                                </li>
                            )} */}
                            {can('product-sales-report.view') && (
                                <li>
                                    <Link to="/product-sales" state={{ back: null, from: null, transition: 'fade' }} className="flex items-center space-x-4">
                                        <div className={`${url === '/product-sales' ? 'bg-neutral-900 ring-[1.5px] ring-offset-2 ring-neutral-900' : 'bg-neutral-700'} p-2 text-white rounded-full`}>
                                            <ReportAnalytics className="w-6 h-6" />
                                        </div>
                                        <div>
                                            Product Sales
                                        </div>
                                        {url === '/product-sales' && (<div className="flex-grow border-t border-neutral-900"></div>)}
                                    </Link>
                                </li>
                            )}
                        </ul>
                    </div>
                )}

                {(can('stock-value-report.view') || can('stock-opname-report.view')) && (
                    <div className="space-y-2">
                        <div className="text-xs">
                            Data
                        </div>
                        <ul className="space-y-6">
                            {can('stock-value-report.view') && (
                                <li>
                                    <Link to="/stock-value" state={{ back: null, from: null, transition: 'fade' }} className="flex items-center space-x-4">
                                        <div className={`${url === '/stock-value' ? 'bg-neutral-900 ring-[1.5px] ring-offset-2 ring-neutral-900' : 'bg-neutral-700'} p-2 text-white rounded-full`}>
                                            <Box className="w-6 h-6" />
                                        </div>
                                        <div>
                                            Stock Value
                                        </div>
                                        {url === '/stock-value' && (<div className="flex-grow border-t border-neutral-900"></div>)}
                                    </Link>
                                </li>
                            )}
                            {can('stock-opname-report.view') && (
                                <li>
                                    <Link to="/stock-opname" state={{ back: null, from: null, transition: 'fade' }} className="flex items-center space-x-4">
                                        <div className={`${url === '/stock-opname' ? 'bg-neutral-900 ring-[1.5px] ring-offset-2 ring-neutral-900' : 'bg-neutral-700'} p-2 text-white rounded-full`}>
                                            <Box className="w-6 h-6" />
                                        </div>
                                        <div>
                                            Stock Opname
                                        </div>
                                        {url === '/stock-opname' && (<div className="flex-grow border-t border-neutral-900"></div>)}
                                    </Link>
                                </li>
                            )}
                        </ul>
                    </div>
                )}
            </nav>
        </motion.div>
    )
}

export default Sidebar