import { useEffect } from "react"
import { useLocation, useNavigate, useOutletContext, useSearchParams } from "react-router-dom"
import moment from "moment/moment"
import nProgress from "nprogress"
import { useAuth } from "contexts/auth-context"
import { Pagination, Transition } from "components"
import { lang } from "config"
import { CloudDownload } from "components/icons/outline"
import toast from "react-hot-toast"
import axios from "axios"
import Filter from "./filter"
import useStockValueRepositories from "repositories/stock-value"

export default function List() {
    // Logged in user ability
    const { user, currentBranch } = useAuth()

    // Transition
    const { title, transition, setTransition } = useOutletContext()

    // React router navigate hook
    const navigate = useNavigate()

    // React router location hook
    const location = useLocation()

    // Query parameters
    const [searchParams, setSearchParams] = useSearchParams()

    const page = searchParams.get('page')
    const search = searchParams.get('search')
    const productUnit = searchParams.get('product_unit')
    const branch = searchParams.getAll('branch_id[]') ?? currentBranch?.id
    const date = searchParams.get('date') ?? moment().subtract(1, 'days').format('Y-MM-DD')

    const { data: resourceData, isLoading: isLoadingResourceData, mutate: mutateResourceData } = useStockValueRepositories({
        page,
        search,
        product_unit: productUnit,
        branch_id: branch,
        date
    })

    useEffect(() => {
        window.history.replaceState({}, document.title)

        if (transition.reverse) {
            setTransition({ type: 'slide', reverse: false })
        }
    }, [])

    // Watch changes on petty cash data and loading state
    useEffect(() => {
        if (search === "") {
            navigate(``, { replace: true })
        }

        if (isLoadingResourceData) {
            nProgress.start()
        } else {
            nProgress.done()
        }
    }, [resourceData, isLoadingResourceData])

    const sendData = (type) => {
        const timestamp = new Date().toISOString()

        toast.loading("Loading", {
            id: `toast-${timestamp}`
        })

        axios.post(`${process.env.REACT_APP_REPORT_API_URL}/stock-value/export`, {
            type,
            product_unit: productUnit,
            branch_id: branch,
            date,
            phone: user?.phone
        }).then((response) => {
            toast.success(response.data.message, {
                id: `toast-${timestamp}`
            })
        }).catch((error) => {
            toast.error(error.response.data.message, {
                id: `toast-${timestamp}`
            })
        })
    }

    // Data filter handlers
    const updateFilter = (value) => {
        setSearchParams(value)
    }

    const removeFilter = () => {
        setSearchParams({})
    }

    return (
        <Transition type="fade">
            <div className="pb-4 space-y-6 lg:pb-8">
                <div className="flex items-center justify-between text-xs">
                    <div>
                        <Filter onSubmit={updateFilter} onRemove={removeFilter} data={Object.fromEntries(Object.entries({ branch, productUnit, date }).filter(([_, v]) => v != null))} />
                    </div>
                    <div className="flex items-center space-x-2">
                        <button onClick={() => sendData('xlsx')} className={`bg-neutral-800 inline-flex items-center px-4 py-3 space-x-2 text-white transition rounded-xl active:hover:scale-90`}>
                            <CloudDownload className="w-4 h-4" strokeWidth={1.5} />
                            <span>Export XLSX</span>
                        </button>
                    </div>
                </div>
                {/* {JSON.stringify(searchParams.getAll('branch_id[]'))} */}
                <div className="overflow-x-auto border border-neutral-200 rounded-xl">
                    <table className="min-w-full overflow-x-auto divide-y divide-neutral-200">
                        <thead className="bg-neutral-50 rounded-t-3xl">
                            <tr>
                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Outlet</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Product Name</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">SKU</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Collection Date</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Current Stock</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">HPP (Average)</th>
                                <th scope="col" className="px-6 py-3 text-xs font-medium text-left uppercase text-neutral-500 whitespace-nowrap">Value</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-neutral-200">
                            {/* When loading */}
                            {isLoadingResourceData && (
                                <tr className="text-center">
                                    <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                        {lang.loading_data}
                                    </td>
                                </tr>
                            )}

                            {/* When there are no list available */}
                            {resourceData?.data.length === 0 && !search && !isLoadingResourceData && (
                                <tr className="text-center">
                                    <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                        {lang.no_data}
                                    </td>
                                </tr>
                            )}

                            {/* When there are no list available on searching */}
                            {resourceData?.data.length === 0 && search && !isLoadingResourceData && (
                                <tr className="text-center">
                                    <td colSpan="10" className="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                        {lang.no_result}
                                    </td>
                                </tr>
                            )}

                            {resourceData?.data.length > 0 && resourceData.data.map((row) => (
                                <tr key={row.id}>
                                    <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                        {row.outlet}
                                    </td>
                                    <td className="px-6 py-4 text-xs font-medium text-neutral-900 whitespace-nowrap">
                                        {row.product_unit}
                                    </td>
                                    <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                        {row.sku}
                                    </td>
                                    <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                        {moment(row.date).format('MMMM D, YYYY')}
                                    </td>
                                    <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                        {row.current_stock}
                                    </td>
                                    <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                        {Intl.NumberFormat('id-Id', { style: 'currency', currency: "IDR" }).format(row.hpp_amount)}
                                    </td>
                                    <td class="px-6 py-4 text-xs text-neutral-500 whitespace-nowrap">
                                        {Intl.NumberFormat('id-Id', { style: 'currency', currency: "IDR" }).format(row.total)}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <Pagination links={resourceData?.links} from={resourceData?.from} to={resourceData?.to} total={resourceData?.total} />
            </div>
        </Transition>
    )
}