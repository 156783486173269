import { Helmet } from "react-helmet"

import Sidebar from "components/partials/sidebar"
import Topbar from "components/partials/topbar"
import React, { useEffect, useState } from "react"
import { useLocation, useOutlet, useNavigationType, Link } from "react-router-dom"
import { AnimatePresence, motion, useAnimationControls } from "framer-motion"
import { findRoute } from "helpers/route-helper"
import { reversedSlideVariant } from "components/transition"

const AppLayout = () => {
    const page = useAnimationControls()
    const location = useLocation()
    const route = findRoute(location.pathname)

    const [title, setTitle] = useState(route?.title)
    const [transition, setTransition] = useState({ type: 'fade', reverse: false })

    const [isSidebarOpen, setIsSidebarOpen] = useState(false)

    const navigate = useNavigationType()

    const [prevRouteState, setPrevRouteState] = useState({})

    useEffect(() => {
        const getPrevRouteState = async () => {
            setPrevRouteState({
                back: location.state?.back,
                transition: location.state?.transition
            })
        }

        getPrevRouteState()
    }, [location.pathname])

    // useLayoutEffect(() => {
    //     document.documentElement.scrollTo(0, 0)
    // }, [location.pathname])

    const AnimatedOutlet = () => {
        // const o = useOutlet()
        // const [outlet] = useState(o)

        // return <>{outlet}</>

        const [outlet] = useState(useOutlet({ title, setTitle, transition, setTransition }))

        return outlet
    }

    useEffect(() => {
        setTitle(route?.title)
    }, [location])

    useEffect(() => {
        if (isSidebarOpen) {
            page.start({
                x: '16rem'
            })
        } else {
            page.start({
                x: 0
            })
        }
    }, [isSidebarOpen])

    // const variant = transition?.type === 'slide' ? (transition?.reverse ? reversedSlideVariant : slideVariant) : defaultVariant

    return (
        <>
            <Helmet>
                <title>{`${title} – ${process.env.REACT_APP_NAME}`}</title>
            </Helmet>
            <div className="flex overflow-hidden">
                <Sidebar isSidebarOpen={isSidebarOpen} />
                <motion.div onClick={isSidebarOpen ? () => setIsSidebarOpen(false) : () => { }} animate={page} transition={{ type: "spring", damping: 20 }} className="w-full max-h-screen min-h-screen overflow-x-hidden overflow-y-auto bg-white border-8 inset-2 border-neutral-100 rounded-2xl">
                    <Topbar title={title} isSidebarOpen={isSidebarOpen} toggleSidebar={() => setIsSidebarOpen(!isSidebarOpen)} />
                    <div className="m-4 space-y-16 lg:m-8">
                        <div>
                            {prevRouteState?.back ? (
                                <>
                                    <button key={prevRouteState?.back}>
                                        <Link to={location.state?.from} state={{ back: null, from: location.pathname, transition: { type: 'slide', reverse: false } }} className="transition hover:opacity-50">
                                            <motion.h3 layout transition={{ type: "spring", damping: 20 }} layoutId={prevRouteState?.back} className="text-sm">{prevRouteState?.back}</motion.h3>
                                        </Link>
                                    </button>
                                    <motion.div
                                        key={location.pathname}
                                        variants={reversedSlideVariant}
                                        initial="initial"
                                        animate="animate"
                                        exit="exit"
                                        className="relative">
                                        <h1 key={location.pathname} className="text-3xl font-medium">
                                            {title}
                                        </h1>
                                    </motion.div>
                                </>
                            ) : (
                                <div>
                                    <button className="cursor-default">
                                        <motion.h1 layout transition={{ type: "spring", damping: 20 }} layoutId={title} className="text-3xl font-medium">
                                            {title}
                                        </motion.h1>
                                    </button>
                                </div>
                            )}
                        </div>
                        <div className="relative">
                            <AnimatePresence mode="sync" initial={false}>
                                <div key={location.pathname} className="absolute inset-0">
                                    <AnimatedOutlet />
                                </div>
                            </AnimatePresence>
                        </div>
                    </div>
                </motion.div >
            </div >
        </>
    )
}

export default AppLayout