import { createBrowserRouter, createHashRouter } from "react-router-dom"

import GuestRoute from "routes/middleware/guest"
import ProtectedRoute from "routes/middleware/protected"

import Dashboard from "pages/dashboard"
import Approve from "pages/approval/approve"
import Submitted from "pages/approval/submitted"
import Login from "pages/auth/login"
import Register from "pages/auth/register"
import NoRole from "pages/no-role"
import { show as showDashboard } from "./loader/dashboard"
import { load as loadMainData } from "./loader/main"
import { loadAutoRedirect as loadAutoRedirectApproval } from "./loader/approval"
import { load as loadApproval } from "./loader/approval"

import { getByUser as getBranchByUser } from "./loader/branch"
import SelectBranch from "pages/select-branch"
import { lang } from "config"
import AlreadySubmitted from "pages/approval/already-submitted"
import Error from "pages/error"
import { List as SalesReportList } from "pages/sales"
import { List as SalesReconReportList } from "pages/sales-recon"
import { List as ProductSalesReportList } from "pages/product-sales"
import { List as StockValueReportList } from "pages/stock-value"
import { List as StockOpnameReportList } from "pages/stock-opname"

import AppLayout from "layouts/app-layout"

export const routeList = [
    // Root loader
    {
        loader: loadMainData,
        errorElement: <Error />,
        children: [
            // Guest
            {
                path: "/register",
                element: (
                    <GuestRoute>
                        <Register title={lang.title.sign_up} />
                    </GuestRoute>
                )
            },
            {
                path: "/login",
                element: (
                    <GuestRoute>
                        <Login title={lang.title.sign_in} />
                    </GuestRoute>
                )
            },

            {
                path: "/branch/select",
                element: (
                    <ProtectedRoute needToHaveRole={true}>
                        <SelectBranch title={lang.title.select_branch} />
                    </ProtectedRoute>
                ),
                loader: getBranchByUser
            },
            {
                path: "/approve/:number",
                key: 'approve',
                element: (
                    <ProtectedRoute needToHaveRole={false} needToSelectBranch={false}>
                        <Approve />
                    </ProtectedRoute>
                ),
                loader: loadAutoRedirectApproval
            },
            {
                path: "/approve/:number/submitted",
                element: (
                    <ProtectedRoute needToHaveRole={false} needToSelectBranch={false}>
                        <Submitted title="Approval Submitted" />
                    </ProtectedRoute>
                )
            },
            {
                path: "/approve/:number/already",
                element: (
                    <ProtectedRoute needToHaveRole={false} needToSelectBranch={false}>
                        <AlreadySubmitted title="Approval Already Submitted" />
                    </ProtectedRoute>
                ),
                loader: loadApproval
            },
            {
                path: "/no-role",
                element: (
                    <ProtectedRoute>
                        <NoRole title="No Role Assigned" />
                    </ProtectedRoute>
                )
            },

            // Protected
            {
                middleware: "protected",
                element: (
                    <ProtectedRoute>
                        <AppLayout />
                    </ProtectedRoute>
                ),
                children: [
                    {
                        path: "/",
                        index: true,
                        title: "Dashboard",
                        element: (
                            <Dashboard />
                        ),
                        loader: showDashboard
                    },
                    {
                        path: "/sales",
                        title: "Sales Report",
                        element: (
                            <SalesReportList />
                        )
                    },
                    {
                        path: "/sales-recon",
                        title: "Sales Reconciliation Report",
                        element: (
                            <SalesReconReportList />
                        )
                    },
                    {
                        path: "/product-sales",
                        title: "Product Sales Report",
                        element: (
                            <ProductSalesReportList />
                        )
                    },
                    {
                        path: "/stock-value",
                        title: "Stock Value Report",
                        element: (
                            <StockValueReportList />
                        )
                    },
                    {
                        path: "/stock-opname",
                        title: "Stock Opname Report",
                        element: (
                            <StockOpnameReportList />
                        )
                    }
                ]
            }
        ]
    }
]

export const routes = process.env.NODE_ENV === 'development' ? createBrowserRouter(routeList) : createHashRouter(routeList)

export default routes