import { useEffect, useRef, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import Transition from "components/transition"

// eCharts
import * as echarts from 'echarts/core'
// import { TooltipComponent, LegendComponent, GridComponent } from 'echarts/components'
// import { LineChart } from 'echarts/charts'
// import { LabelLayout } from 'echarts/features'
// import { SVGRenderer } from 'echarts/renderers'

// Components
import { Banner } from "components"
import { MoodSmile, TopologyRing } from "components/icons/outline"
import Filter from "pages/dashboard/filter"
import SelectDescription from "components/forms/select-description"
import ChartContainer from "components/charts-container"
import { SVGRenderer } from "echarts/renderers"
import { LineChart } from "echarts/charts"
import { GridComponent, TooltipComponent, LegendComponent } from "echarts/components"

const orderStats = [
    {
        title: "Cash",
        description: "Rp190.000",
        options: {
            grid: {
                show: false,
                left: 0,
                top: 5,
                right: 0,
                bottom: 5
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                show: false
            },
            yAxis: {
                type: 'value',
                show: false
            },
            color: '#737373',
            series: [
                {
                    data: [
                        2,
                        4,
                        5,
                        6,
                        4,
                        2,
                        4
                    ],
                    type: 'line',
                    smooth: true,
                    symbol: 'circle',
                    symbolSize: 8,
                    lineStyle: {
                        color: '#737373'
                    },
                    areaStyle: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                                offset: 0,
                                color: 'rgba(115, 115, 115, .5)'
                            },
                            {
                                offset: 1,
                                color: 'rgba(115, 115, 115, 0)'
                            }
                        ])
                    }
                }
            ]
        },
        components: [
            SVGRenderer,
            LineChart,
            GridComponent
        ]
    },
    {
        title: "Static QR",
        description: "Rp0",
        options: {
            grid: {
                show: false,
                left: 0,
                top: 5,
                right: 0,
                bottom: 5
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                show: false
            },
            yAxis: {
                type: 'value',
                show: false
            },
            color: '#737373',
            series: [
                {
                    data: [
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0
                    ],
                    type: 'line',
                    smooth: true,
                    symbol: 'circle',
                    symbolSize: 8,
                    lineStyle: {
                        color: '#737373'
                    },
                    areaStyle: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                                offset: 0,
                                color: 'rgba(115, 115, 115, .5)'
                            },
                            {
                                offset: 1,
                                color: 'rgba(115, 115, 115, 0)'
                            }
                        ])
                    }
                }
            ]
        },
        components: [
            SVGRenderer,
            LineChart,
            GridComponent
        ]
    },
    {
        title: "EDC",
        description: "Rp0",
        options: {
            grid: {
                show: false,
                left: 0,
                top: 5,
                right: 0,
                bottom: 5
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                show: false
            },
            yAxis: {
                type: 'value',
                show: false
            },
            color: '#737373',
            series: [
                {
                    data: [
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        0
                    ],
                    type: 'line',
                    smooth: true,
                    symbol: 'circle',
                    symbolSize: 8,
                    lineStyle: {
                        color: '#737373'
                    },
                    areaStyle: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                                offset: 0,
                                color: 'rgba(115, 115, 115, .5)'
                            },
                            {
                                offset: 1,
                                color: 'rgba(115, 115, 115, 0)'
                            }
                        ])
                    }
                }
            ]
        },
        components: [
            SVGRenderer,
            LineChart,
            GridComponent
        ]
    },
    {
        title: "Payment Gateway",
        description: "Rp190.000",
        options: {
            grid: {
                show: false,
                left: 0,
                top: 5,
                right: 0,
                bottom: 5
            },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                show: false
            },
            yAxis: {
                type: 'value',
                show: false
            },
            color: '#737373',
            series: [
                {
                    data: [
                        0,
                        0,
                        0,
                        2,
                        1,
                        0,
                        1
                    ],
                    type: 'line',
                    smooth: true,
                    symbol: 'circle',
                    symbolSize: 8,
                    lineStyle: {
                        color: '#737373'
                    },
                    areaStyle: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            {
                                offset: 0,
                                color: 'rgba(115, 115, 115, .5)'
                            },
                            {
                                offset: 1,
                                color: 'rgba(115, 115, 115, 0)'
                            }
                        ])
                    }
                }
            ]
        },
        components: [
            SVGRenderer,
            LineChart,
            GridComponent
        ]
    }
]

const productStats = [
    // {
    //     title: "Foom X",
    //     content: {
    //         title: "2",
    //         description: "sold"
    //     }
    // },
    // {
    //     title: "Caliburn AK3",
    //     content: {
    //         title: "8",
    //         description: "sold"
    //     }
    // }
]

export default function Dashboard() {
    const location = useLocation()
    const navigate = useNavigate()

    const orderStats = [
        {
            title: "Cash",
            description: "Rp0",
            data: [
                // 2, 1, 2, 1, 5
                0, 0, 0, 0, 0
            ]
        },
        {
            title: "Static QR",
            description: "Rp0",
            data: [
                // 2, 1, 2, 1, 5
                0, 0, 0, 0, 0
            ]
        },
        {
            title: "EDC",
            description: "Rp0",
            data: [
                // 2, 1, 2, 1, 5
                0, 0, 0, 0, 0
            ]
        },
        {
            title: "Payment Gateway",
            description: "Rp0",
            data: [
                // 2, 1, 2, 1, 5
                0, 0, 0, 0, 0
            ]
        },
    ]

    // Set line chart data ref
    // const dataLineChart = useRef()

    // Get data from loader
    // const data = useLoaderData()

    const [chartList, setChartList] = useState([])
    const [transition, setTransition] = useState(location.state?.transition)

    useEffect(() => {
        // Clear cache state and reload page
        if (location.state?.cache === 'clear') {
            window.history.replaceState({}, document.title)
            navigate(0)
        }

        if (orderStats.length) {
            var list = orderStats.map((row) => {
                return {
                    title: row.title,
                    description: row.description,
                    options: {
                        grid: {
                            show: false,
                            left: 0,
                            top: 5,
                            right: 0,
                            bottom: 5
                        },
                        xAxis: {
                            type: 'category',
                            boundaryGap: false,
                            show: false
                        },
                        yAxis: {
                            type: 'value',
                            show: false
                        },
                        color: '#737373',
                        series: [
                            {
                                data: row.data,
                                type: 'line',
                                smooth: true,
                                symbol: 'circle',
                                symbolSize: 8,
                                lineStyle: {
                                    color: '#737373'
                                },
                                areaStyle: {
                                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                        {
                                            offset: 0,
                                            color: 'rgba(115, 115, 115, .5)'
                                        },
                                        {
                                            offset: 1,
                                            color: 'rgba(115, 115, 115, 0)'
                                        }
                                    ])
                                }
                            }
                        ]
                    },
                    components: [
                        SVGRenderer,
                        LineChart,
                        GridComponent
                    ]
                }
            })

            setChartList(list)
        }
    }, [])

    return (
        <Transition type={transition}>
            <div className="pb-4 space-y-8">
                {/* <Banner type="default" icon={(<MoodSmile className="w-6 h-6" strokeWidth={1.5} />)} title="Your total revenue" subtitle="Rp1.000.000" /> */}
                <div className="flex items-end justify-between">
                    <div>
                        <h1 className="text-2xl font-medium">Total revenue</h1>
                        <h1 className="text-3xl font-semibold text-transparent bg-clip-text bg-gradient-to-tl from-neutral-400 to-neutral-800">Rp0</h1>
                    </div>
                    <div className="flex items-center space-x-4">
                        <Filter data={[]} onSubmit={() => { }} onRemove={() => { }} />
                    </div>
                </div>
                <div className="grid gap-4 md:grid-cols-2">
                    {chartList.map((row, index) => (
                        <div className="overflow-hidden border rounded-xl">
                            {/* <div className={`${index % 2 !== 0 ? 'border-l' : ''} ${(index + 1) !== orderStats.length && (index + 1) !== (orderStats.length - 1) ? 'border-b' : ''}`}> */}
                            <div className="p-4">
                                <div className="flex items-center justify-between">
                                    <h4 className="text-sm font-medium">{row.title}</h4>
                                    <div>
                                        {/* <SelectDescription selection={[{ label: "Today" }]} keyValue={(data) => data.label} value={"Today"} title={(data) => data.label} /> */}
                                        {/* <select name="" id="" className="text-xs rounded-md border-neutral-200 focus:outline-none focus:border-neutral-400 focus:ring focus:ring-neutral-200">
                                            <option value="">Simple</option>
                                            <option value="">Detailed</option>
                                        </select> */}
                                    </div>
                                </div>
                                <p className="text-2xl font-semibold text-neutral-600">{row.description}</p>
                            </div>
                            <div className="h-16">
                                {row.options && row.components && (
                                    <ChartContainer options={row.options} components={row.components} />
                                )}
                            </div>
                        </div>
                    ))}
                </div>
                <div className="space-y-2">
                    <span className="text-xs text-neutral-500">Per Product</span>
                    <div className="grid gap-4 md:grid-cols-2">
                        {productStats.map((row, index) => (
                            <div className="border rounded-xl">
                                {/* <div className={`${index % 2 !== 0 ? 'border-l' : ''} ${(index + 1) !== orderStats.length && (index + 1) !== (orderStats.length - 1) ? 'border-b' : ''}`}> */}
                                <div className="p-4 space-y-4">
                                    <div className="flex items-center justify-between">
                                        <h4 className="text-sm font-medium">{row.title}</h4>
                                        <div>
                                            {/* <SelectDescription selection={[{ label: "Today" }]} keyValue={(data) => data.label} value={"Today"} title={(data) => data.label} /> */}
                                            {/* <select name="" id="" className="text-xs rounded-md border-neutral-200 focus:outline-none focus:border-neutral-400 focus:ring focus:ring-neutral-200">
                                            <option value="">Simple</option>
                                            <option value="">Detailed</option>
                                        </select> */}
                                        </div>
                                    </div>
                                    <div className="flex items-center space-x-4">
                                        <div className="p-3 rounded-lg bg-neutral-100">
                                            <TopologyRing className="w-6 h-6 text-neutral-600" strokeWidth={2} />
                                        </div>
                                        <div>
                                            <p className="text-2xl font-semibold text-neutral-600">{row.content.title}</p>
                                            <p className="font-semibold text-neutral-600">{row.content.description}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </Transition>
    )
}