import { useEffect, useRef } from "react"

// eCharts
import * as eCharts from 'echarts/core'

export default function ChartContainer({ options = {}, components = [] }) {
    const chart = useRef()

    useEffect(() => {
        // Initialize
        eCharts.use(components)
        eCharts.init(chart.current).setOption(options)
    }, [chart])

    return (
        <div className="w-full h-full" id="chart" ref={chart}></div>
    )
}